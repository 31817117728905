<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import { socket } from "@/services/socket";
import SimpleBar from "simplebar"



export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,

  },
  data() {
    return {
      simpleBar: null,
      scrollableElement: null,
      page: 1,
      topic_limit: 10,
      topic_tot_pages: 0,
      messages: [],
      chat_messages: null,
      sender_name: "",
      receiver_name: "",
      topics: [],
    };
  },
  methods: {
    connect() {
      socket.connect();
    },
    getTopics(page) {
      this.http.post(`topics/paginate`, { page: page, limit: this.topic_limit }, null, true).then((res) => {
        this.topics = res.data;
        this.topic_tot_pages = Math.ceil(res.tot / this.topic_limit)
        console.log('topics :', this.topics)
        setTimeout(() => {
          this.scrollableElement.scrollTop = this.scrollableElement.scrollHeight
        }, 200);
      })
    },
    msg(obj) {


      obj.has_images = 0
      obj.topic_id = Number(this.$route.params.topic_id)
      obj.source = 0
      console.log("message :", obj)
      // this.http.post(`topic-chats`, { topic_id: obj.topic_id, chat: obj.msg, has_images: obj.has_images, source: obj.source }).then((res) => {
      // })

      socket.emit('chat', obj)
      this.chat_messages = ''
      setTimeout(() => {
        this.scrollableElement.scrollTop = this.scrollableElement.scrollHeight - this.scrollableElement.clientHeight
      }, 100);

    },
    disconnect() {
      socket.disconnect();
    },
    chooseTopic(id) {
      socket.emit("remove_me_from_room", Number(this.$route.params.topic_id))
      this.http.get(`topics/chat?id=${id}&`, null, null, true).then((res) => {
        this.receiver_name = res.data?.name
        this.messages = res.data?.msgs
        this.messages.forEach((e, index) => {
          if (e.source)
            this.messages[index].user = this.receiver_name

          else
            this.messages[index].user = this.sender_name

        })
        this.$router.push({ name: "technical_support", params: { topic_id: id } })
        console.log('router :', this.$route.params.topic_id)
        socket.emit("add_me_to_room", Number(id))


      })

    }
  },
  mounted() {

    if (this.$route.params.topic_id) this.chooseTopic(this.$route.params.topic_id)


    this.simpleBar = new SimpleBar(this.$refs.scrollWrapper)
    this.scrollableElement = this.simpleBar.getScrollElement()

    const loggeduser = localStorage.user;
    const decoded_user_data = loggeduser

    this.sender_name = decoded_user_data.user_data?.name

    this.getTopics(1)
    console.log("route params :", this.$route.params)
    socket.emit("add_me_to_room", Number(this.$route.params.topic_id))
    socket.on("chat", async (obj) => {

      if (obj.source)
        obj.user = this.receiver_name

      else
        obj.user = this.sender_name

      if (this.scrollableElement.scrollTop > this.scrollableElement.scrollHeight * 0.70) {
        // Scroll to the bottom of the container
        this.messages.push(obj)
        setTimeout(() => {
          this.scrollableElement.scrollTop = this.scrollableElement.scrollHeight;
        }, 200);
      }
      else {
        this.messages.push(obj)

      }
      console.log(obj)

    })
  },
  unmounted() {


    socket.emit("remove_me_from_room", this.$route.params.topic_id)
  }
};
</script>

<template>
  <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    :title="$t('menu.menuitems.technical_support.subItems.technical_support')" />
  <div class="row">
    <div class="col-12 col-md-9">

      <div class="card">
        <div class="card-body">


          <div ref="scrollWrapper" data-simplebar style="max-height: 450px; ">
            <ul class="list-group list-group-flush">



              <li class="list-group-item  py-3 "
                :class="{ 'sender': message.source == 0, 'receiver': message.source == 1, 'mb-3': index !== this.messages.length - 1 }"
                :style="index == this.messages.length - 1 ? ' margin-bottom: 3rem; ' : ''"
                v-for="(message, index) in messages" :key="index">
                <div class="d-flex">
                  <div class="avatar-xs me-3 flex-shrink-0">
                    <!-- <img :src="message?.pfp" alt="" class="img-fluid d-block rounded-circle" /> -->
                    <div class="avatar-title rounded-circle bg-light text-primary">
                      <i class="bx bxs-user"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="username font-size-14 mb-1">
                      {{ message.user }}
                      <small class="timestamp float-end">{{ new Date(message.created).toLocaleString("en-GB") }}</small>
                    </h5>
                    <p class="">
                      {{ message.chat }}
                    </p>



                  </div>
                </div>
              </li>





            </ul>
          </div>
        </div>
        <form @submit.prevent="msg({ chat: chat_messages })" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" id="form"
          action="">
          <input id="input" autocomplete="off" v-model="chat_messages" /><button
            :disabled="!chat_messages">Send</button>
        </form>
      </div>
    </div>
    <div class="col">
      <b-list-group class="pos-user mb-3" style="min-height: 40vh;">
        <b-list-group-item :active="$route.params.topic_id == topic.id" button @click="chooseTopic(topic.id)"
          class="justify-content-start d-flex" v-for="(topic, index) in topics " :key="index">

          <!-- <div class="avatar-xs me-3">
             <div class="avatar-xs me-3">
              <div class="avatar-title rounded-circle bg-light text-primary">
                <img src="/assets/images/users/avatar-2.jpg" alt="" class="img-fluid d-block rounded-circle" />
                <span class="notification-count"> 5 </span>
              </div>

            </div> -->
          <p class="text-start"> {{ topic.title }} </p>

        </b-list-group-item>


      </b-list-group>

      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a class="page-link" href="javascript: void(0);" @click="getTopics(page - 1)" aria-label="Previous">
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in topic_tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="getTopics(p)">{{ p }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == topic_tot_pages }">
          <a class="page-link" href="javascript: void(0);" @click="getTopics(page + 1)" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
/* Style for the sender's chat bubble */
/* Style for the sender's chat bubble */
.list-group-item.sender {
  background-color: #2a3042;
  /* Set sender's background color */
  color: #fff;
  /* Set sender's text color */
  border-radius: 15px;
  /* Set sender's chat bubble border-radius */
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
  /* Set sender's box shadow */
}

/* Style for the receiver's chat bubble */
.list-group-item.receiver {
  background-color: #f1f1f1;
  /* Set receiver's background color */
  border-radius: 15px;
  /* Set receiver's chat bubble border-radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Set receiver's box shadow */
}

/* Style for the sender's avatar image */
.list-group-item.sender .avatar-xs img {
  border: 2px solid #007bff;
  /* Set sender's avatar border color */
}

/* Style for the receiver's avatar image */
.list-group-item.receiver .avatar-xs img {
  border: 2px solid #f1f1f1;
  /* Set receiver's avatar border color */
}

/* Style for the sender's username, and timestamp */
.list-group-item.sender h5.username,
.list-group-item.sender small.timestamp {
  color: #dbdcdf;
}

/* Style for the receiver's timestamp */


/* Style for the reply link in both sender and receiver */
.list-group-item .text-success {
  color: #28a745;
  /* Set your desired text color for the reply link */
  text-decoration: none;
}

.list-group-item .text-success:hover {
  text-decoration: underline;
}



#form {
  background: rgba(0, 0, 0, 0.15);
  padding: 0.25rem;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 3rem;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  z-index: 999;
}

#input {
  border: none;
  padding: 0 1rem;
  flex-grow: 1;
  border-radius: 2rem;
  margin: 0.25rem;
}

#input:focus {
  outline: none;
}

#form>button {
  background: #333;
  border: none;
  padding: 0 1rem;
  margin: 0.25rem;
  border-radius: 13px;
  outline: none;
  color: #fff;
}

.pos-user {

  .list-group-item {
    align-items: center;

    .avatar-title {
      position: relative;

      span.notification-count {
        position: absolute;
        top: -5px;
        left: -14px;
        background-color: #ff0000;
        color: #ffffff;
        font-size: 10px;
        padding: 2px 6px;
        border-radius: 53%;
      }
    }

    p {
      margin-bottom: 0;

    }
  }
}
</style>
